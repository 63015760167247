<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="逝者详情"
      :ok-button-props="{ style: { display: 'none' } }"
      :cancel-button-props="{ style: { margin: 'auto' } }"
      width="55%"
    >
      <a-tabs
        type="card"
        v-decorator="['tags', {
        }]"
        @change="handleChange"
      >
        <a-tab-pane key="basic" tab="基本信息" class="tab-pane-scroll">
          <basic-info
            :id="record.id"
          />
        </a-tab-pane>
        <a-tab-pane key="family" tab="家属信息" class="tab-pane-scroll">
          <family-info
            :id="record.id"
          />
        </a-tab-pane>
        <a-tab-pane key="genealogy" tab="家族谱" class="tab-pane-scroll">
          <genealogy ref="child" :id="record.order_id" />
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import BasicInfo from '@/views/service_order_deaths/system/show/BasicInfo'
import familyInfo from '@/views/service_order_deaths/system/show/FamilyInfo'
import Genealogy from '@/components/genealogy/genealogy'
export default {
  name: 'ShowDeathInfo',
  components: {
    BasicInfo,
    familyInfo,
    Genealogy
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleChange(e) {
      return {
        tab: e.tab,
        index: e.index
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tab-pane-scroll {
  height: 443px;
  overflow: auto;
}
</style>
